import { useGetSavedPlaylistsQuery } from "services/graphql";

export function useGetSavedPlaylists() {
  const perPage = 10;
  const {
    data,
    fetchMore,
    loading: loadingSavedPlaylists,
    error: errorSavedPlaylists,
  } = useGetSavedPlaylistsQuery({
    variables: {
      pagination: {
        first: perPage,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageInfoSavedPlaylists = data?.me?.savedPlaylists?.pageInfo;
  const savedPlaylists = data?.me?.savedPlaylists?.edges.map(edge => edge.node);

  const fetchMoreSavedPlaylists = () => {
    fetchMore({
      variables: {
        pagination: {
          first: savedPlaylists?.length,
          after: pageInfoSavedPlaylists?.endCursor,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousEntry = previousResult.me?.savedPlaylists;
        const newEdges = fetchMoreResult.me?.savedPlaylists.edges;

        return {
          me: {
            ...previousResult.me,
            savedPlaylists: {
              ...fetchMoreResult.me?.savedPlaylists,
              edges: [...previousEntry.edges, ...newEdges],
            },
          },
        };
      },
    });
  };

  return {
    isFetchingInitialSavedPlaylists: !savedPlaylists && loadingSavedPlaylists,
    savedPlaylists,
    errorSavedPlaylists,
    pageInfoSavedPlaylists,
    fetchMoreSavedPlaylists,
    loadingSavedPlaylists,
  };
}
